html {
  height: 100%;
}

body {
  margin: 0;
  background-image: url('./assets/images/MM2\ Background Login.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
}

label.required::after {
  content: " *";
  color: red;
}

#test {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}